import React, { useEffect, useState } from 'react';
import axios from "axios";
import DataTable from 'react-data-table-component';
import { fetchMembersDisenroll, fetchProgramList, reEnrollMember, fetchProgramConditionPathway, getCareNavigators, fetchAvailableAppointmentSlots, fetchMembersDisenrollPast } from '../../../services/medengage/Service';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { formatDate } from '../../utils/CommonLibrary';
import { format } from 'date-fns';
import { CustomLoader } from '../../utils/CommonLibrary';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { error } from 'jquery';

function PastRequestTablev2() {
    const formObj = {
        userId: "",
        cnId: "",
        programId: "",
        conditionId: "",
        pathwayId: "",
        callDate: "",
        callTime: "",
        pathwayMonth: 1,
        remarks: '',
        membersDisenrollLogId: ""
    }
    const [formData, setFormData] = useState(formObj);
    const [programConditionPathways, setProgramConditionPathways] = useState([]);
    const [careNavigatorsList, setCareNavigatorsList] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [showNoSlots, setShowNoSlots] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [reEnrollmentError, setReEnrollmentError] = useState("");
    const [showReEnrollmentModal, setShowReEnrollmentModal] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [newMembersDisenrollList, setNewMembersDisenrollList] = useState([])
    const [data, setData] = useState([]);
    const [isNewLoading, setIsNewLoading] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("disEnrollRequestedDate");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    useEffect(() => {
        fetchNewMembersDisenrollList();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, selectedProgram, selectedCondition, download]);

    useEffect(() => {
        fetchDropDownList();
    }, []);

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    const fetchNewMembersDisenrollList = async () => {
        try {
            setIsNewLoading(true);
            fetchMembersDisenrollPast(searchQuery, selectedProgram, selectedCondition,
                page, perPage, sortColumn, sortDirection, download)
                .then((response) => {
                    if (response.data.code == 200) {
                        if (download == "N") {
                            setData(response.data.data.logs);
                            setTotalRows(response.data.data.totalRows);
                        } else {
                            handleExport(response.data.data.logs);
                            setDownload('N');
                        }
                    }
                    setIsNewLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    };

    /*const fetchNewMembersDisenrollList = async () => {
        setIsNewLoading(true);
        try {
            const response = await fetchMembersDisenroll();

            if (response.data.status === 'success') {
                setNewMembersDisenrollList(response.data.data.filter(data => data.requestStatus !== 'Pending'));
            } else {
                setNewMembersDisenrollList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsNewLoading(false);
        }
    }*/

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.disEnrollRequestedDate,
            format: row => row.disEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: false,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: false,
        },
        {
            name: 'Program',
            selector: row => row.carePrograms,
            sortable: false,
        },
        {
            name: 'Condition',
            selector: row => row.chronicConditions,
            sortable: false,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentTime,
            format: row => row.enrollmentTime,
            sortable: true,
        },
        {
            name: 'Care Navigator Name',
            selector: row => row.careName,
            sortable: false,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: false,
        },
        {
            name: 'Care Navigator Remarks',
            selector: row => row.disEnrollmentNote,
            sortable: false,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.disEnrollmentNote }} />
            ),
        },
        {
            name: 'Action Taken By',
            selector: row => row.approvedBy,
            sortable: false,
        },
        {
            name: 'Date Actioned',
            id: 'approvedDate',
            selector: row => row.approvedDate,
            format: row => row.approvedDate,
            sortable: false,
        },
        {
            name: 'Dis-EnrollmentType',
            id: 'disEnrollmentType',
            selector: row => row.disEnrollmentType,
            format: row => row.disEnrollmentType,
            sortable: false,
        },
        {
            name: 'Care Coordinator Remarks',
            id: 'aprrovedNote',
            selector: row => row.aprrovedNote,
            format: row => row.aprrovedNote,
            sortable: false,
        },
        {
            name: 'Status',
            sortable: false,
            //selector: row => row.requestStatus,
            cell: row => (
                <span style={{ color: row.requestStatus.includes('Approved') ? '#1279be' : '#d60023' }}>
                    {row.requestStatus.includes('Approved') ? <i className="bi bi-check-lg"></i> : <i className="bi bi-x-lg"></i>} {row.requestStatus}
                </span>
            ),
        },
        {
            name: 'Re-enroll',
            sortable: false,
            //selector: row => row.showReenrollment,
            cell: row => (
                <span>
                    {row.showReenrollment === "Y" ? <a href="#" className="btn-approve" onClick={() => {
                        setShowNoSlots(false);
                        setTimeSlots([]);
                        setFormData(formObj);
                        setReEnrollmentError("");
                        openReenrollmentModal(row.userId, row.logId);
                    }
                    }>Re-enroll</a> : ""}
                </span>
            ),
        }
    ];

    const openReenrollmentModal = async (userId, logId) => {
        setIsNewLoading(true);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchProgramConditionPathway(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getCareNavigators()
                        .then((response) => {
                            setCareNavigatorsList(response.data.data.users);
                        });
                    setProgramConditionPathways(response.data.data.programs);
                    setFormData({
                        ...formData,
                        userId: userId,
                        membersDisenrollLogId: logId
                    });
                    setIsNewLoading(false);
                    setShowReEnrollmentModal(true);
                } else {
                    setIsNewLoading(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();

    }

    const getSlots = async (cnId) => {
        if (selectedDate) {
            setShowNoSlots(false);
            setIsNewLoading(true);
            try {
                let response = await fetchAvailableAppointmentSlots(format(selectedDate, 'yyyy-MM-dd HH:mm'), cnId);
                if (response.data.code === 200) {
                    setTimeSlots(response?.data?.data);
                    setShowNoSlots(true);
                }

            } catch (error) {
                console.log(error);
            } finally {
                setIsNewLoading(false);
            }
        } else {
            alert('Please select both date and time.');
        }
    };

    const validateForm = () => {
        setReEnrollmentError("");
        let errorFound = false;
        if (formData.programId == "") {
            setReEnrollmentError("Select the program");
            errorFound = true;
        } else if (formData.conditionId == "") {
            setReEnrollmentError("Select the condition");
            errorFound = true;
        } else if (formData.pathwayId == "") {
            setReEnrollmentError("Select the pathway");
            errorFound = true;
        } else if (formData.cnId == "") {
            setReEnrollmentError("Select the care navigator");
            errorFound = true;
        } else if (formData.callDate == "" || formData.time == "") {
            setReEnrollmentError("Select the appointment slot");
            errorFound = true;
        } else if (formData.remarks == "") {
            setReEnrollmentError("Enter the remarks");
            errorFound = true;
        }
        setTimeout(() => {
            setReEnrollmentError("");
        }, 2000)
        if (errorFound) return false;
        else return true;
    }
    const reenrollUser = async () => {
        setReEnrollmentError("");
        try {
            if (validateForm()) {
                setIsNewLoading(true);
                const request = axios.CancelToken.source();
                let ct = {
                    cancelToken: request.token
                }
                await reEnrollMember(formData.userId, formData.cnId, formData.programId, formData.conditionId, formData.pathwayId, formData.callDate, formData.callTime, formData.pathwayMonth, formData.remarks, formData.membersDisenrollLogId, ct)
                    .then((response) => {
                        setIsNewLoading(false);
                        if (response.data.code === 200) {
                            fetchNewMembersDisenrollList();
                            setShowReEnrollmentModal(false);
                            setFormData(formObj);
                            setIsNewLoading(false);
                        } else {
                            setReEnrollmentError(response.data.data.message);
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                return () => request.cancel();
            }
        } catch (error) {
            console.log(error);
            setIsNewLoading(false);
        }
    }


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('');
        setSelectedCondition('');
    };

    const filteredData = newMembersDisenrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) || user.membershipNo == searchQuery;

        const matchesProgram = selectedProgram === 'All' || user.carePrograms.toLowerCase() === selectedProgram.toLowerCase();
        const matchesCondition = selectedCondition === 'All' || user.chronicConditions.toLowerCase() === selectedCondition.toLowerCase();
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });

    const handleExport = (data) => {

        const modifiedData = data.map(item => {
            return {
                'Request Date': item.disEnrollRequestedDate,
                'Member ID': item.membershipNo,
                'Main Membership ID': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Email': item.email,
                'Mobile No': item.mobile,
                'Program': item.carePrograms,
                'Condition': item.chronicConditions,
                'Enrollment Date': item.enrollmentTime,
                'Care Navigator Name': item.careName,
                'Supervisor Name': item.supervisorName,
                'Reason': item.reason,
                'Care Navigator Remarks': item.disEnrollmentNote,
                'Action Taken By': item.approvedBy,
                'Date Actioned': item.approvedDate,
                'Dis-EnrollmentType': item.disEnrollmentType,
                'Care Coordinator Remarks': item.aprrovedNote,
                'Status': item.requestStatus
            };
        });


        modifiedData.forEach(function (v) { delete v.disEnrollBy; delete v.logId; delete v.membershipNo; delete v.mobile; delete v.userId; });

        exportTableData("De-EnrollPastRequest", modifiedData);
    };

    console.log(formData);

    return (
        <>
            <LoaderLoader isLoading={isNewLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Programs</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Conditions</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={() => setDownload('Y')} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchNewMembersDisenrollList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
            <Modal id="reEnrollment" centered show={showReEnrollmentModal} onHide={() => {
                setShowReEnrollmentModal(false);
                setFormData(formObj);
            }} size="" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Member Re-enrollment</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <label className="col-form-label">Select Program: <span className='required'>*</span></label>
                                <select className='form-control control-input-ui' name="programId" value={formData.programId} onChange={(e) => setFormData({
                                    ...formData,
                                    programId: e.target.value
                                })}>
                                    <option value="">Select</option>
                                    {
                                        programConditionPathways.map((program, pindex) => {
                                            return <option value={program.programId}>{program.programName}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {
                                formData.programId != "" &&
                                <div className='col-12 mb-3'>
                                    <label className="col-form-label">Select Condition: <span className='required'>*</span></label>
                                    <select
                                        className='form-control control-input-ui'
                                        name="conditionId"
                                        value={formData.conditionId || ''}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            conditionId: e.target.value
                                        })}
                                    >
                                        <option value="">Select</option>
                                        {
                                            Array.isArray(programConditionPathways) && programConditionPathways.length > 0 &&
                                            (programConditionPathways.find((program) => program.programId == formData.programId)?.conditions || [])
                                                .map((condition, cindex) => (
                                                    <option key={cindex} value={condition.conditionId}>{condition.conditionName}</option>
                                                ))
                                        }
                                    </select>
                                </div>
                            }
                            {
                                formData.conditionId != "" &&
                                <div className='col-12 mb-3'>
                                    <label className="col-form-label">Select Pathway: <span className='required'>*</span></label>
                                    <select
                                        className='form-control control-input-ui'
                                        name="pathwayId"
                                        value={formData.pathwayId || ''}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            pathwayId: e.target.value
                                        })}
                                    >
                                        <option value="">Select</option>
                                        {
                                            Array.isArray(programConditionPathways) && programConditionPathways.length > 0 &&
                                            (programConditionPathways.find((program) => program.programId == formData.programId)?.conditions.find((condition) => condition.conditionId == formData.conditionId)?.pathways || [])
                                                .map((pathway, paindex) => (
                                                    <option key={paindex} value={pathway.pathwayId}>{pathway.pathwayName}</option>
                                                ))
                                        }
                                    </select>
                                </div>
                            }
                            {
                                formData.pathwayId &&
                                <div className='col-12 mb-3'>
                                    <label className="col-form-label">Start from Pathway Month: <span className='required'>*</span></label>
                                    <select
                                        className='form-control control-input-ui'
                                        name="pathwayMonth"
                                        value={formData.pathwayMonth}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            pathwayMonth: e.target.value
                                        })}
                                    >
                                        {[...Array(12)].map((_, index) => (
                                            <option key={index} value={index + 1}>
                                                {index + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            }
                            {
                                formData.pathwayMonth > 0 &&
                                <div className='col-12 mb-3'>
                                    <label className="col-form-label">Care Navigator: <span className='required'>*</span></label>
                                    <select
                                        className='form-control control-input-ui'
                                        name="cnId"
                                        value={formData.cnId}
                                        onChange={(e) => setFormData({
                                            ...formData,
                                            cnId: e.target.value
                                        })}
                                    >
                                        <option value="">Select</option>
                                        {
                                            careNavigatorsList.map((item, index) => (
                                                <option key={index} value={item.id}>{item.userName}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                            }
                            {
                                formData.cnId != '' &&
                                <>
                                    <div className="col mb-2 pe-1">
                                        <label class="col-form-label">Appointment Date and Time <span className='required'>*</span></label>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={(date) => setSelectedDate(date)}
                                            showTimeSelect
                                            timeInputLabel="Time:"
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            placeholderText="Select Date and Time"
                                            className='form-control input-field-ui'
                                            minDate={new Date()} // Disable past dates 
                                            minTime={formData.callDate > new Date() ? new Date(new Date().setHours(0, 0, 0, 0)) : new Date()}
                                            maxTime={new Date().setHours(23, 59, 59)}
                                        />
                                    </div>
                                    <div className="col-auto mb-2 mt-4 ps-0">
                                        <label class="col-form-label">&nbsp;</label>
                                        <button id="searchBtn" className="btn btn-primary program-save-btn mt-3" onClick={() => getSlots(formData.cnId)} style={{ width: "140px" }}>Search</button>
                                    </div>
                                </>
                            }
                            {
                                formData.cnId != "" &&
                                <div className="col-md-12">
                                    {timeSlots[0] ? (<label className="col-form-label">Slot Available</label>) : (showNoSlots === true && !timeSlots[0]) ? (<label className="col-form-label">No Slot Available</label>) : ""}
                                    <div className="d-flex flex-wrap" style={{ justifyContent: "space-between !important" }}>
                                        {timeSlots &&
                                            timeSlots.map(
                                                ({
                                                    carenavigatorId,
                                                    slots: agentSlots,
                                                }) => (
                                                    <div className="time_slot-d-flex">
                                                        {agentSlots && agentSlots.map((data) => (
                                                            <>
                                                                <div
                                                                    className={`col-auto mb-2 time_slot_book  ${data === formData.callTime
                                                                        ? "active"
                                                                        : ""
                                                                        }`} style={{ justifyContent: "center" }} onClick={() => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                callDate: format(selectedDate, 'yyyy-MM-dd'),
                                                                                callTime: data
                                                                            })
                                                                        }} >
                                                                    {data}
                                                                </div>
                                                            </>
                                                        ))}
                                                    </div>
                                                )
                                            )}
                                    </div>
                                </div>
                            }


                            <div className='col-12 mb-3'>
                                <label className='col-form-label'>Remarks: <span className='required'>*</span></label>
                                <textarea name="remarks" className='form-control control-input-ui' onChange={(e) => setFormData({
                                    ...formData,
                                    remarks: e.target.value
                                })} value={formData.remarks}></textarea>
                            </div>
                            <div className='col-12 text-center'>
                                <div className={`${reEnrollmentError != "" ? "errorDiv" : "hide"}`}>{reEnrollmentError}</div>
                                <button className="btn btn-primary save-btn me-3" onClick={() => reenrollUser()}>Save</button>
                            </div>
                        </div>

                    }
                </Modal.Body >
            </Modal >
        </>
    );
}

export default PastRequestTablev2;
