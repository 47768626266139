import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { ModalBoxYesNo } from '../../utils/CommonLibrary';
import { cancelCareTransfer, allTransferUsers } from '../../../services/medengage/Service';
import { NotificationToast, showToast } from './NotificationToast';
import { Modal } from "react-bootstrap";


const TransferListTable = ({ data: initialData, reload }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedReason, setSelectedReason] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [data, setData] = useState(initialData);
    const [showNewList, setShowNewList] = useState([]);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('success');
    const [showModal, setShowModal] = useState(false);
    const [showModalNew, setShowModalNew] = useState(false);
    const [modalTitle, setModalTitle] = useState('Cancel Transfer');
    const [modalClass, setModalClass] = useState('md');
    const [modalBody, setModalBody] = useState('');
    const [actionId, setActionId] = useState(false);
    const [total, setTotal] = useState(0);

    const [actionData, setActionData] = useState([]);

    const handleClose = () => setShowModal(false);
    const handleCloseNew = () => setShowModalNew(false);

    const openCancelModal = (totalMembers, oldCare, newAction) => {
        setActionId(newAction);
        setTotal(totalMembers);
        let member = totalMembers == 1 ? 'member' : 'members';
        setModalBody('Are you sure you want to cancel the transfer of [' + totalMembers + '] ' + member + ' of Care Navigator [' + oldCare + '] ?')
        setShowModal(true);
    }

    const openListModal = (newAction) => {
        try {
            allTransferUsers(newAction)
                .then((response) => {
                    // alert('call')
                    setShowModalNew(true);
                    setShowNewList(response.data.data.users);
                    // if(response.data.code == 200){
                    //     setShowModalNew(true);

                    // }else{
                    //     setMessage('Something Went Wrong!');
                    //     setType('error');
                    //     showToast('Something Went Wrong!', 'error','top-right');
                    // }
                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    const cancelTransfer = () => {
        try {
            cancelCareTransfer(actionId, total)
                .then((response) => {
                    if (response.data.data.status == 1) {


                        setMessage(response.data.data.msg);
                        setType('success');
                        showToast(response.data.data.msg, 'success', 'top-right');

                        setTimeout(() => {
                            reload();
                        }, 1000);
                        setShowModal(false);
                        setActionId('');
                        setTotal(0);

                    } else {
                        setMessage(response.data.data.msg);
                        setType('error');
                        showToast(response.data.data.msg, 'error', 'top-right');
                    }
                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    const columns = [
        {
            name: 'Transfer Date',
            selector: row => row.transferDate,
            sortable: true,
        },
        {
            name: 'Original Care Navigator',
            selector: row => row.oldCareNavigator,
            sortable: true,
        },
        {
            name: 'New Care Navigator',
            selector: row => row.newCareNavigator,
            sortable: true,
        },
        {
            name: 'Reason for Transfer',
            selector: row => row.reason,
            sortable: true,
        },
        {
            name: 'Transfer Type',
            selector: row => (row.transferType == "Permanant") ? "Permanent" : row.transferType,
            sortable: true,
        },
        {
            name: 'No. of Members',
            selector: row => row.totalUsers,
            sortable: true,
            cell: row => (
                <div>
                    <button className="btn btn-link" onClick={(e) => openListModal(row.actionId)}>{row.totalUsers}</button>
                </div>
            ),
        },
        {
            name: 'Transfer Plan Date',
            selector: row => row.startDate,
            sortable: true,
        },
        {
            name: 'Transfer Back Date',
            selector: row => row.endDate,
            sortable: true,
        },
        {
            name: 'Transfer Status',
            selector: row => row.transferStatus,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            cell: row => (
                <div>
                    {row.transferStatus == 'Scheduled' && <button className="btn btn-Cancel" onClick={(e) => openCancelModal(row.totalUsers, row.oldCareNavigator, row.actionId)} >Cancel</button>}
                </div>
            ),
        },
    ];



    const columnsNew = [
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: true,
        },
        {
            name: 'Original Care Navigator',
            selector: row => row.oldCareNavigator,
            sortable: true,
        },
        {
            name: 'New Care Navigator',
            selector: row => row.newCareNavigator,
            sortable: true,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: true,
        },
    ];


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedReason('All');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            (user.oldCareNavigator && user.oldCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.newCareNavigator && user.newCareNavigator.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.transferType && user.transferType.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.transferStatus && user.transferStatus.toLowerCase().includes(searchQuery.toLowerCase()));

        const matchesReason = selectedReason === 'All' || user.transferType.includes(selectedReason);
        const matchesStatus = selectedStatus === 'All' || user.transferStatus === selectedStatus;

        return matchesSearchQuery && matchesReason && matchesStatus;
    });

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Care Navigator"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedReason}
                            onChange={e => setSelectedReason(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Transfer Type--</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Permanent">Permanent</option>
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Active">Active</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Completed">Completed</option>
                            <option value="Cancelled">Cancelled</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                </div>
            </div>
            {data.length > 0 &&
                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationPerPage={10}
                    striped
                    responsive
                    noHeader
                    noFooter
                    customStyles={customStyles}
                />}


            <Modal dialogClassName="modal-xl modal-dialog-centered" show={showModalNew} onHide={handleCloseNew} id="showListDataTable" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title
                        style={{
                            width: "90%",
                            fontSize: "18px",
                        }}
                    >
                        Transfer Details
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body >
                    <div className="table-responsive mb-0">
                        <DataTable
                            columns={columnsNew}
                            data={showNewList}
                            pagination
                            paginationPerPage={10}
                            striped
                            responsive
                            noHeader
                            noFooter
                            customStyles={customStyles}
                        />
                    </div>
                </Modal.Body>
            </Modal>


            <NotificationToast />

            {showModal ? <ModalBoxYesNo handleClose={handleClose} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass} buttonYes={cancelTransfer} buttonNo={handleClose} /> : null}
        </>
    );
}

export default TransferListTable;
